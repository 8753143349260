import { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";

import "./assets/scss/right_sidebar.scss";
import "./assets/scss/style.scss";
import "./assets/scss/navbar.scss";
import "./assets/scss/content.scss";
import "./assets/scss/dropdown.scss";
import "./assets/scss/filter.scss";
import "./assets/scss/sidebar.scss";
import "./assets/scss/scroll.scss";
import "./assets/scss/slider.scss";
import "./assets/scss/footer.scss";
import "./assets/scss/share.scss";
import "./assets/scss/comment.scss";
import "./assets/scss/advertisement.scss";
import "./assets/scss/profile.scss";
import "./assets/scss/login.scss";
import "./assets/scss/bookmark.scss";
import "./assets/scss/pedoman_siber.scss";
import "./assets/scss/tentang_kami.scss";
import "./assets/scss/redaksi.scss";
import "./assets/scss/kontak.scss";
import "./assets/scss/main_footer.scss";

import logo_medsos from "./assets/images/icons/icon-medsos.svg";
import { BeatLoader } from "react-spinners";

const Home = lazy(() => {
  return Promise.all([
    import("./pages/Home"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Results = lazy(() => {
  return Promise.all([
    import("./pages/Results"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const DetailNews = lazy(() => {
  return Promise.all([
    import("./pages/DetailNews"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Search = lazy(() => {
  return Promise.all([
    import("./pages/Search"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const DetailsScrolls = lazy(() => {
  return Promise.all([
    import("./pages/DetailsScrolls"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Login = lazy(() => {
  return Promise.all([
    import("./pages/Login"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Register = lazy(() => {
  return Promise.all([
    import("./pages/Register"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Category = lazy(() => {
  return Promise.all([
    import("./pages/Category"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Scroll = lazy(() => {
  return Promise.all([
    import("./pages/Scroll"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Profile = lazy(() => {
  return Promise.all([
    import("./pages/Profile"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Live = lazy(() => {
  return Promise.all([
    import("./pages/Live"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const SearchResult = lazy(() => {
  return Promise.all([
    import("./pages/SearchResult"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Bookmark = lazy(() => {
  return Promise.all([
    import("./pages/Bookmark"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const PedomanMediaSiber = lazy(() => {
  return Promise.all([
    import("./pages/PedomanMediaSiber"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const TentangKami = lazy(() => {
  return Promise.all([
    import("./pages/TentangKami"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Redaksi = lazy(() => {
  return Promise.all([
    import("./pages/Redaksi"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const Kontak = lazy(() => {
  return Promise.all([
    import("./pages/Kontak"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const PrivacyAndPolicy = lazy(() => {
  return Promise.all([
    import("./pages/PrivacyAndPolicy"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});

const fallback = () => {
  return (
    <div
      className="fallback-loading d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <img src={logo_medsos} alt="logo-medsos" />
      <br />
      <BeatLoader color="#a1bc04" size={15} />
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Switch>
          <Route exact path="/">
            <Suspense fallback={fallback()}>
              <Home page="beranda" />
            </Suspense>
          </Route>
          <Route exact path="/results">
            <Suspense fallback={fallback()}>
              <Results page="results" />
            </Suspense>
          </Route>
          <Route exact path="/search">
            <Suspense fallback={fallback()}>
              <Search page="search" />
            </Suspense>
          </Route>
          <Route exact path="/searchresult">
            <Suspense fallback={fallback()}>
              <SearchResult page="searchresult" />
            </Suspense>
          </Route>
          <Route exact path="/login">
            <Suspense fallback={fallback()}>
              <Login page="login" />
            </Suspense>
          </Route>
          <Route exact path="/register">
            <Suspense fallback={fallback()}>
              <Register page="register" />
            </Suspense>
          </Route>
          <Route exact path="/scroll">
            <Suspense fallback={fallback()}>
              <Scroll page="scroll" />
            </Suspense>
          </Route>
          <Route exact path="/profile">
            <Suspense fallback={fallback()}>
              <Profile page="profile" />
            </Suspense>
          </Route>
          <Route exact path="/live">
            <Suspense fallback={fallback()}>
              <Live page="live" />
            </Suspense>
          </Route>
          <Route exact path="/disimpan">
            <Suspense fallback={fallback()}>
              <Bookmark page="disimpan" />
            </Suspense>
          </Route>
          <Route exact path="/pedoman_media_siber">
            <Suspense fallback={fallback()}>
              <PedomanMediaSiber page="pedoman_media_siber" />
            </Suspense>
          </Route>
          <Route exact path="/tentang_kami">
            <Suspense fallback={fallback()}>
              <TentangKami page="tentang_kami" />
            </Suspense>
          </Route>
          <Route exact path="/redaksi">
            <Suspense fallback={fallback()}>
              <Redaksi page="redaksi" />
            </Suspense>
          </Route>
          <Route exact path="/kontak">
            <Suspense fallback={fallback()}>
              <Kontak page="kontak" />
            </Suspense>
          </Route>
          <Route exact path="/category/:categories">
            <Suspense fallback={fallback()}>
              <Category page="kategori" />
            </Suspense>
          </Route>
          <Route exact path="/privacy_and_policy">
            <Suspense fallback={fallback()}>
              <PrivacyAndPolicy page="privacy_and_policy" />
            </Suspense>
          </Route>
          <Route exact path="/:id">
            <Suspense fallback={fallback()}>
              <DetailNews page="detail_news" />
            </Suspense>
          </Route>
          <Route exact path="/detail_scrolls/:id">
            <Suspense fallback={fallback()}>
              <DetailsScrolls page="detail_scrolls" />
            </Suspense>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
